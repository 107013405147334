/***background-image: url('https://dev2.psmweb.in/images/068hfe95lz.jpeg'); */



/**   commom styling  */

.text-center{ text-align:center; }
/**   ends */

.footer{
    bottom:0;
    margin:0;
    height:300px;
    background-image: url("../..//images/068hfe95lz.jpeg");
    background-repeat: no-repeat;
    width:100%;
    background-size: cover;
}

.footer p{
    font-size: 25px;
    color:rgb(255, 255, 255);
    text-align: center;
    /* background-color:white; */
    padding:3px;
    margin:5px;
    font-family:'Times New Roman', Times, serif;
    border-bottom:2px solid greenyellow;
}

.footer ol{
    list-style-type: none;
    text-align: center;
}
.footer li{
    color:white;
    list-style-type: none;
    text-align: center;
}

#social-media-icon{  
    color:rgb(255, 255, 255);
    padding:10px;
    text-align: center;
    border:2px solid white;
    border-radius:50%;
}

#social-media-icon:hover{
    color:rgb(119, 216, 110);
    border:2px solid rgb(119, 216, 110);
}

.social-media-icon{
    text-align:center;
    flex:1;
}

.social-media{
    margin-top:50px;
    display:flex;
}

.contact{
    margin-top:50px;
    text-align:left;
    color:white;
}
.contact-center{
    display:flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .footer{
        height:auto;
    }
    .social-media{
        margin-top:20px;
        margin-bottom:20px;
        display:flex;
        flex-direction: row;
    }
    
    .contact{
        width:50%;
        align-content: center;
        text-align: center;
        margin-left:50px;
    }
    .contact-center{
        text-align:center;
    }
}    

@media only screen and (min-width: 600px) {
    /* For tablets: */
    
  }
  
@media only screen and (min-width: 768px) {
    /* For desktop: */

}

