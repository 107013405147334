
.nav-tabs{
    background-color: rgb(255, 255, 255);
    padding:5px;
}

.nav-item button{
    border-bottom:2px solid green;
    color:black;
}
/* .active{
    border-bottom:2px solid green;
    background-color: blue;
} */