.card {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px rgb(99, 221, 252);
}

.card-title {
  border-bottom: 1px solid grey;
  padding: 10px;
}

.span-price {
  color: rgb(230, 34, 34);
  font-size: large;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#view-detail-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 10px rgb(241, 58, 58);
  /* background-color: rgb(230, 34, 34); */
  /* background-color: rgb(185, 29, 102); */
  background-color: rgb(230, 34, 34);
  color: white;
  border: none;
  margin-top: 20px;
}

#get-location-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 10px rgb(241, 58, 58);
  /* background-color: rgb(37, 129, 141); */
  background-color: rgb(230, 34, 34);
  color: white;
  border: none;
  margin-top: 20px;
}

#search-contact-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 10px rgb(241, 58, 58);
  background-color: rgb(230, 34, 34);
  color: white;
  border: none;
  margin-top: 20px;
}

#get-location-btn:hover,
#view-detail-btn:hover,
#search-contact-btn:hover {
  background-color: rgb(86, 206, 136);
  color: white;
}
#search-contact-btn:hover {
  background-color: rgb(86, 206, 136);
  color: #ffffff;
}


.badges-bag{
  margin-top:0px
  
}

.badge-image-count{
  
  background-color: #40ff8f;
  color:black;
  position: relative;
}
.badge-dummy-image{
  margin-left:50px;
  color:black;
  position: relative;
}


.badge-sell{
  margin-left:25px;
  background-color: #ff2929;
  color:rgb(255, 255, 255);
}
.badge-rent{
  margin-left:25px;
  background-color: #26ccff;
  color:rgb(0, 2, 3);
} 


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .row-custom-buttons {
    padding-bottom: 15px;
  }
}
