body {
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}

/* .menu-icon:focus{
  outline: none;
  border: none;
} */

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.navbar {
 top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  justify-items: center;
  align-items: center;
  /* background: linear-gradient(
    to bottom,
    rgb(230, 34, 34) 0%,
    rgb(202, 32, 9) 100%
  ); */
  
  /* background-color: rgb(95, 13, 13); */
  /* display: flex;
 
  position: fixed;
  z-index: 2; */
}

.container-fluid {
  /* background: linear-gradient(
    to bottom,
    rgb(230, 34, 34) 0%,
    rgb(202, 32, 9) 100%
  ); */
  /* background: linear-gradient(
    to bottom,
    rgb(105, 221, 177) 0%,
    rgb(9, 147, 202) 100%
  ); */
  background: linear-gradient(
    to bottom,
    rgb(255, 27, 103) 0%,
    rgb(202, 9, 9) 100%
  );
}

.navbar .brand-name h1 {
  margin: 0;
  padding-left: 20px;
  color: white;
}

.left-navbar {
  display: flex;
  align-items: center;
}
.right-navbar {
  margin-right: 20px;
  justify-items: center;
}
.brand-image img {
  height: 160px;
  width: 160px;
  margin-left: 50px;
}

.text-link {
  font-weight: bolder;
  color: white;
  padding: 5px;
  margin-right: 15px;
  font-size: 14px;
  text-decoration: none;
}
.navbar .text-link:hover {
  font-weight: bolder;
  color: rgb(131, 255, 131);
  border-bottom: 2px solid white;
}

#login-btn {
  height: 30px;
  color: rgb(230, 34, 34);
  font-size: 14px;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bolder;
  margin-top: 30px;
  border: none;
}
#login-btn:hover {
  color: rgb(0, 0, 0);
  background-color: rgb(6, 149, 245);
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .right-navbar {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
}
