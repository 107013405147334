/*-------------- CLASSES FOR COMMON STYLING------ */
* {
  padding-bottom: 0px;
  margin-top: 0px;
}
.flex-1 {
  flex: 1;
}

/*----------------- SEARCH PART------------------ */
.search-container {
  width: 600px;
  display: block;
  margin: auto;
  margin-top: 200px;
}
.search-container-box {
  border: 1px solid rgb(211, 30, 30);
  /* border-radius: 25px; */
  display: flex;
}

.home-img {
  top: 0;
  margin-top: 50px;
  width: 100%;
  height: 400px;
  position: absolute;
  z-index: -1;
  mask-image: linear-gradient(to bottom, rgb(0, 0, 0, 1) 60%, rgb(0, 0, 0, 0));
}

.search-container-box select:focus {
  outline: none;
}

#search-btn {
  color: white;
  height: 50px;
  width: 90px;
  border: none;
  /* border-radius: 17px; */
  cursor: pointer;
  background-color: rgb(211, 30, 30);
}

.search-container-box-select select {
  /* width:200px; */
  width: 100%;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  border-bottom: 1px solid rgb(197, 197, 197);
  height: 50px;
  /* border-radius:17px; */
  margin-left: 0px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: orangered;
  background-color: #fff;
  border:none;
  border-color: none;
  border-bottom: 2px solid red;
  padding-bottom:10px;
  margin-bottom:0px;

}

.nav-tabs {
  font-size: larger;
  border-bottom: 1px solid grey ;
}

.nav-tabs .nav-link:hover{
  color:red;
}

.upload-home-button{
  height:40px;
  width:90%;
  margin-left:5%;
  border:none;
  font-size: 14px;
  border-radius:5px;
  color:white;
  font-weight:bolder;
  text-align: center;
  
  /* background-color:rgb(30, 199, 211); */
  background-color:rgb(30, 211, 151);
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .search-container-box {
    margin-top: 220px;
  }
  .search-container-box-outer {
    top: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 200px;
    /* background-color: rgb(59, 10, 12); */
    /* background:linear-gradient(to bottom , rgb(250, 248, 125) 0% , rgb(255, 255, 255) 100%); */
    background: linear-gradient(
      to bottom,
      rgb(125, 194, 250) 0%,
      rgb(255, 255, 255) 100%
    );
    z-index: -1;
  }
  .search-container-box {
    border: 0px solid rgb(211, 30, 30);
    /* border-radius: 25px; */
    display: flex;
    flex-direction: column;
  }
  .search-container {
    width: 90%;
    display: block;
    margin: auto;
    margin-top: 100px;
    text-align: center;
  }
  #search-btn {
    color: white;
    height: 40px;
    width: 100%;
    border: none;
    /* border-radius: 17px; */
    background-color: rgb(211, 30, 30);
  }

  .box-container h3 {
    border-bottom: 1px solid rgb(207, 207, 207);
    color: rgb(211, 30, 30);
    padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .box-container-2 h3 {
    border-bottom: 1px solid rgb(207, 207, 207);
    color: rgb(211, 30, 30);
    padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .flex-1 {
    flex: 1;
    padding: 0;
    margin: 0;
  }

  .flex-for-mob {
    display: flex;
    flex-direction: row;
  }

  .box-container {
    flex: 1;
    display: block;
    margin: auto;
    margin-top: 100px;
  }
  .box-container-2 {
    flex: 1;
    display: block;
    margin: auto;
    margin-top: 30px;
  }
  .boxes {
    flex: 1;
    width: 100%;
  }
  .box {
    display: flex;
    min-height: 150px;
    text-align: center;
    justify-content: center;
    /* border:1px solid black; */
    align-items: center;
    border-radius: 50%;
    background-color: rgb(209, 242, 255);
    margin: 10px;
  }
  .box span {
    /* background-color: rgb(216, 33, 33); */
    color: rgb(22, 22, 117);
    font-weight: bolder;
    padding: 2px;
    width: 100px;
    vertical-align: middle;
    /* border-radius: 5px; */
    
  }
  
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .search-container-box-outer {
    top: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 500px;
    /* background-color: rgb(59, 10, 12); */
    background: linear-gradient(
      to bottom,
      rgb(125, 194, 250) 0%,
      rgb(255, 255, 255) 100%
    );
    /* background:linear-gradient(to bottom , rgb(250, 248, 125) 0% , rgb(255, 255, 255) 100%); */
    z-index: -1;
  }

  .flex-1-for-pc {
    flex: 1;
  }
  .flex-for-pc {
    display: flex;
    flex-direction: row;
  }

  .box-container {
    width: 100%;
    margin-top: 250px;
  }
  .box-container-2 {
    width: 100%;
    margin-top: 50px;
  }
  .boxes {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .box-container h3 {
    border-bottom: 1px solid rgb(207, 207, 207);
    color: rgb(211, 30, 30);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .box-container-2 h3 {
    border-bottom: 1px solid rgb(207, 207, 207);
    color: rgb(211, 30, 30);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .box {
    height: 150px;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px;
    /* border:1px solid black; */
    display: flex;
    background-color: rgb(209, 242, 255);
    align-items: center;
    /* border-radius: 50%; */
    border-radius: 20px;
  }

  .box span {
    /* background-color: rgb(255, 255, 255); */
    color: rgb(22, 15, 121);
    font-weight: bold;
    padding: 2px;
    min-width: 100px;
    vertical-align: middle;
    border-radius: 10px;
    text-decoration: none;
  }
}
