.flex {
  display: flex;
}

.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.container {
  padding-top: 60px;
}
.property-upload-form {
  width: 60%;
  margin-left: 25%;
  margin-top: 100px;
  padding: 2%;
  background-color: rgb(255, 253, 130);
}

.property-upload-form label {
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}

.property-upload-form input {
  width: 250px;
  height: 30px;
  margin-top: 5px;
  border: 1px solid rgb(199, 199, 199);
}

.imagecontainer {
  position: relative;
  width: 300px;
}
.uploadimage {
  opacity: 1;
  display: block;
  width: 300px;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.imagecontainer:hover .image {
  opacity: 0.3;
}

.imagecontainer:hover .middle {
  opacity: 1;
}
