#mt-20{
    margin-top:20px;
}

.register-page{
    /* padding-top:1px; */
   
}
.register-lock{
    display:block;
    margin:auto;
    text-align: center;
    
}
#register-lock{
    padding:10px;
    border-radius:35px;
    background-color: rgb(255, 255, 255);
    color:rgb(230, 34, 34);
    box-shadow: 0px 0px 10px rgb(255, 255, 255);
}

.register-page-form{
    height:100%;
    width:600px;
    display:block;
    margin:auto;
    /* margin-top:100px; */
    border-radius:25px;
    background-color: rgb(179, 12, 12);
    /* background-color: rgb(230, 34, 34); */
    box-shadow: 0px 0px 30px grey;  
    align-content: center;
    color:white;
    font-weight:bolder;
}
.register-page-form label{
    margin-top:20px;
}
.register-form{
    padding:100px;
    padding-top:50px;
}

.register-page-form form input{
    height:30px;
    width:400px;
    border:none;
    margin-top:7px;
    padding-left:10px;
}
.register-page-form form input:focus{
    outline:none;
}

.register-page-login-btn{
    text-align: center;
}
#register-page-login-btn{
    width:150px;
    height:40px;
    border:none;
    font-weight:bolder;
    text-align: center;
    color:rgb(230, 34, 34);
    margin-top:20px;
    background-color: rgb(255, 255, 255);
}
#register-page-login-btn:hover{
    color:rgb(255, 255, 255);
    background-color: rgb(35, 211, 58);
}
#register-page-new-btn{
    width:150px;
    height:40px;
    border:none;
    font-weight:bolder;
    text-align: center;
    color:rgb(230, 34, 34);
    margin-top:20px;
    background-color: rgb(255, 255, 255);
}
#register-page-new-btn:hover{
    color:rgb(255, 255, 255);
    background-color: rgb(35, 211, 58);
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    *{
        padding:0;
        margin:0;
    }
    .flex{
        display:flex;
        flex-direction:column;
    }
    .register-lock{
        padding-top:70px;
    }
    .register-page-form{
        width:100%;
        height:auto;
        box-shadow:none;
        padding:0px;
        background-color: none;
        align-content: center;
        align-content: center;
    }
    .register-form{
        padding:0px;
        height:100vh;
        width:100%;
        background:linear-gradient(to bottom right , rgb(94, 15, 15) 0% , rgb(243, 12, 50) 100%);
    }
    .register-input-container label{
        margin-left:10px;
        margin-top:30px;
        padding-top:20px;
    }
    .register-page-form form input{
        width:90%;
        display:block;
        margin:auto;
        height:45px;
        margin-top:15px;
        border:1px solid grey;
        border-bottom:2px solid red;
    }
    .register-page-form form input:focus{
        width:90%;
        border-bottom:2px solid rgb(12, 5, 110);
    }
    .ml-30{
        margin-left:0px;
    }
    #register-page-login-btn{
        width:94%;
        height:45px;
        padding-left:10px;
        padding-right:10px;
        color:green;
        border-radius:5px;
        margin-top:20px;
        border:1px solid green;
        background-color: rgb(82, 224, 129);
    }
    #register-page-login-btn:hover{
        color:rgb(255, 255, 255);
        background-color: rgb(35, 211, 58);
    }
    #register-page-new-btn{
        width:94%;
        height:45px;
        padding-left:10px;
        padding-right:10px;
        color:rgb(19, 18, 87);
        border-radius:5px;
        margin-top:20px;
        text-align:center;
        border:1px solid rgb(0, 30, 128);
        background-color: rgb(82, 103, 224);
    }
  }
  
  @media only screen and (min-width: 600px) {
    /* For tablets: */
    
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
   
  }