
.no-result-img{
    width:90%;
    height:500px;
    object-fit: cover;
    /* background-image: url("../images/not_found_poconest_web.jpg");
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top:300px;
    margin-top:200px;*/
    display:block;
    margin:auto;
    justify-content: center;
    align-items: center;
}

.no-result-img img{
    width:330px;
    height:300px;
}

.search-result-page{
    padding-top:100px;
}