.property-detail-page-view {
  display: flex;
  margin-top: 70px;
}
.property-detail-page-carousel {
  padding: 20px;
}

.property-detail-page-carousel #carousel {
  height: 400px;
}
#carousel img {
  height: 100%;
  object-fit: contain;
}

#btn-buttons {
  display: flex;
  flex-direction: column;
}

#view-detail-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 10px rgb(241, 58, 58);
  /* background-color: rgb(230, 34, 34); */
  /* background-color: rgb(185, 29, 102); */
  background-color: rgb(230, 34, 34);
  color: white;
  border: none;
  margin-top: 20px;
}

#get-location-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 10px rgb(241, 58, 58);
  /* background-color: rgb(37, 129, 141); */
  background-color: rgb(230, 34, 34);
  color: white;
  border: none;
  margin-top: 20px;
}

#search-contact-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 10px rgb(241, 58, 58);
  background-color: rgb(230, 34, 34);
  color: white;
  border: none;
  margin-top: 20px;
}

#get-location-btn:hover,
#view-detail-btn:hover,
#search-contact-btn:hover {
  background-color: rgb(86, 206, 136);
  color: white;
}
#search-contact-btn:hover {
  background-color: rgb(86, 206, 136);
  color: #ffffff;
}

#property-detail-page-view-heading {
  font-size: 20px;
  border-bottom: 1px solid grey;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#span-id {
  font-size: large;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
#span-id-price {
  color: rgb(230, 34, 34);
  font-size: large;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 100px;
  right: 8px;
  text-align: center;
}

.my-float {
  margin-top: 22px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .property-detail-page-view {
    display: flex;
    flex-direction: column;
  }
  .details {
    padding: 10px;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .property-detail-page {
    padding-top: 50px;
  }

  #get-location-btn {
    height: 60px;
    width: 60px;
  }
  #search-contact-btn {
    height: 60px;
    width: 60px;
    margin-bottom: 50px;
  }
  .float {
    bottom: 175px;
  }
  .float-icon-size {
    font-size: 25px;
    padding-right: 3px;

  }
  /* .carousel-fixed{
    background-color: gray;
    min-height:600px;
  }
  .carousel-fixed img{
    background-color: gray;
  } */
}
